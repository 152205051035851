<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        {{
          inputs.language === 'en'
            ? 'What are the molecular geometries of each of the non-hydrogen atoms indicated in the structure below?'
            : 'Quelle est la géométrie moléculaire qui décrit la structure 3D des atomes (non-hydrogène) identifiés dans la structure suivante?'
        }}
      </p>

      <p class="pl-8 mb-4">
        <v-img style="max-width: 180px" :src="imageName" />
      </p>

      <v-select
        v-model="inputs.atomA"
        :items="items"
        item-text="text"
        item-value="value"
        label="Atome A:"
        class="mb-0"
        style="width: 250px"
      >
        <template #item="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
        <template #selection="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
      </v-select>

      <v-select
        v-model="inputs.atomB"
        :items="items"
        item-text="text"
        item-value="value"
        label="Atome B:"
        class="mb-0"
        style="width: 250px"
      >
        <template #item="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
        <template #selection="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
      </v-select>

      <v-select
        v-model="inputs.atomC"
        :items="items"
        item-text="text"
        item-value="value"
        label="Atome C:"
        class="mb-n2"
        style="width: 250px"
      >
        <template #item="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
        <template #selection="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
      </v-select>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'Question500',
  components: {CalculationInput, ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        atomA: null,
        atomB: null,
        atomC: null,
      },
      items: [
        {text: 'Linéaire', value: 'linear'},
        {text: 'Coudée', value: 'bent'},
        {text: 'Plan trigonal', value: 'trigonalPlanar'},
        {text: 'Tétraédrique', value: 'tetrahedral'},
        {text: 'Pyramidal trigonal', value: 'trigonalPyramidal'},
        {text: 'Carré plan', value: 'squarePlanar'},
        {text: 'Octaédrique', value: 'octahedral'},
      ],
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/molecularGeometriesWithinMolecule1.png';
    },
  },
};
</script>
<style scoped></style>
